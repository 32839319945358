import React, { useContext } from 'react'
import './index.css'
import { LanguageContext } from '../../../lang'

const BookingInfoSection = ({ data,footer, rideId, children }) => {
	const {
		firstName,
		lastName,
		phone,
		taxiModel,
		taxiLicenseNo,
		taxiColor
	} = data ? data : {}
	const {
		translations: { rideAccepted }
	} = useContext(LanguageContext)
	const {
		driverDetailsText,
		modelText,
		licenceText,
		colorText,
		nameText,
		phoneText,
		taxi
	} = rideAccepted
	return (

			<div className='infoChildContainer'>
				{children}
				<div className='col-12 text-center mx-auto '>
					<span className='blueHeadings'>{`${taxi}`}</span>
					<div className='detailsText'>
						<span>{`${modelText}: ${taxiModel} `}</span>
					</div>

					<div className=' detailsText2'>
						<span>{` ${licenceText}: ${taxiLicenseNo} `}</span>
					</div>
					<div className='detailsText2'>
						<span> {`${colorText}: ${taxiColor}`}</span>
					</div>
					<br />

				</div>
				<div className='col-12 text-center mx-auto '>
					<h2 className='blueHeadings'>{driverDetailsText}</h2>
					<div className='col-sm-12 detailsText'>
						<span>{`${nameText}: ${firstName} ${lastName}`}</span>
					</div>
					<div className='detailsText'>
						<span>
							{phoneText}: {`${phone}`}
						</span>
					</div>
				</div>
				{footer}
			</div>
	)
}

export default BookingInfoSection
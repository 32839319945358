import gql from "graphql-tag";

export const GET_RIDE_DATA = gql`
  query($id: String!) {
    ride(id: $id) {
      id
      date
      pickupLoc
      destLoc
      custId
      custFullName
      custPhone
      taxiTypeId
      currentTaxiRate
      rideType
      billType
      pickupDate
      pickupDateForText
      status
      totalFare
      discountPercent
      discountAmount
      netFare
      distance
      startTime
      endTime
      numberOfPassenger
      paymentType
      paymentStatus
      custEmail
      driverId
      driver {
        firstName
        lastName
        phone
        taxiLicenseNo
        taxiModel
        taxiColor
      }
      pickupHouseNo
      dropHouseNo
      roundTrip
      destCoordinateX
      destCoordinateY
      pickupCoordinateX
      pickupCoordinateY
      returnDate
      acceptedTime
      status
      driverEarn
    }
  }
`;

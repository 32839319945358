import React, { Component } from "react";
import ThankYou from "../components/thankyou";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import { isBrowser } from "../utils/general";

const Thankyou = () => {
  if (isBrowser()) {
    localStorage.clear();
  }
  return (
    <Layout>
      <SEO title="Thankyou" />
      <ThankYou />
    </Layout>
  );
};

export default Thankyou;

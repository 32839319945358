import React, { useContext } from "react";
import BookingInfoSection from "./Booking-Info-Section";
import { LanguageContext } from "../../lang";
import { useQuery } from "@apollo/react-hooks";
import { GET_RIDE_DATA } from "./graphql/queries";
import { isBrowser } from "../../utils/general";
import { navigate } from 'gatsby'
import "./index.css";

const parseRideInfo = (ride) => {
  const {
    destLoc,
    pickupLoc,
    roundTrip,
    dropHouseNo,
    pickupHouseNo,
    destCoordinateX,
    pickupCoordinateY,
    pickupCoordinateX,
    destCoordinateY,
    discountAmount,
    discountPercent,
    distance,
    driverEarn,
    estimatedTimeInMins,
    netFare,
    priceKm,
    priceMin,
    startPrice,
    totalFare,
    custEmail,
    custFullName,
    custPhone,
    pickupDate,
    pickupDateForText,
    rideType,
    status,
  } = ride;
  return {
    step1: {
      destCoordinateX,
      destCoordinateY,
      destLoc,
      dropHouseNo,
      pickupCoordinateX,
      pickupCoordinateY,
      pickupHouseNo,
      pickupLoc,
      roundTrip,
    },
    step2: {
      estimateFair: {
        discountAmount,
        discountPercent,
        distanceKM: distance,
        driverEarn,
        estimatedTimeInMins,
        netFare,
        priceKm,
        priceMin,
        startPrice,
        totalFare,
        custEmail,
        custFullName,
        custPhone,
        pickupDate,
        pickupDateForText,
        rideType,
      },
    },
    step3: {
      pickupDate,
      pickupDateForText,
      rideType,
    },
    step4: {
      custEmail,
      custFullName,
      custPhone,
    },
    status,
  };
};

const OrderBooked = ({ state }) => {
  let id;
  if(isBrowser()){
    const { search } = location;
    id = search.substr(4);
  }
  if (!id) return <div>Something went wrong</div>;
  let { data, loading } = useQuery(GET_RIDE_DATA, {
    variables: {
      id,
    },
  });
  const {
    translations: { thank_you },
  } = useContext(LanguageContext);
  const { thank_YouText, buttonText, trackText } = thank_you

  if (loading) return "loading...";

  return (
    
    <div style={{marginTop:80}}>
            <div className='bg-white p-4'>
              <BookingInfoSection rideId={id} data={data && data.ride.driver} footer={
                <div className='not-found-btn-wrap mt-4 pl-3'>
                  <a onClick={() => navigate(`/ride-tracking/?id=${id}`)} className='btn try-again-btn'>
                  {trackText}
                  </a>
                  &nbsp;
                  <a onClick={() => navigate('/')} className='btn try-again-btn'>
                    {buttonText}
                  </a>
                </div>
              }>
                <p className='not-found-text p-3'>{thank_YouText}</p>
              </BookingInfoSection>
            </div>
        </div>
 );
};

export default OrderBooked;